import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import PageLayout from "../components/hrbfpagelayout"
import Head from "../components/head"
import marked from "marked"
import MarketoForm from "../components/marketoform"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      formId {
        speakerAgreement
      }
      speakerDetails {
        status
        speakerAgreementTitle
        speakerAgreementDescription {
          speakerAgreementDescription
        }
        speakerAgreementAsSessionSpeaker {
          speakerAgreementAsSessionSpeaker
        }
        speakerAgreementDetails {
          speakerAgreementDetails
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`

const SpeakerAgreement = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  const getMarkdownText = agreementDetails => {
    if (
      agreementDetails &&
      (agreementDetails !== null || agreementDetails !== undefined)
    ) {
      var rawMarkup = marked(agreementDetails, { sanitize: true, breaks: true })
      return { __html: rawMarkup }
    }
  }

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      // registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={`${props?.data?.contentfulEvents?.title} - Speaker Agreement`}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />
      <div className="spk_ag_body">
        <h2
          className="spk_ag_title"
          style={{
            background: `-webkit-linear-gradient(top left, ${props?.data?.contentfulEvents?.headerColor})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {props?.data?.contentfulEvents?.speakerDetails?.speakerAgreementTitle}
        </h2>
        <div
          className="spk_ag_body_p"
          dangerouslySetInnerHTML={getMarkdownText(
            props?.data?.contentfulEvents?.speakerDetails
              ?.speakerAgreementDescription?.speakerAgreementDescription
          )}
        ></div>
        <h3>As a session speaker, you will receive:</h3>
        <p style={{ whiteSpace: "pre-wrap", lineHeight: "35px" }}>
          {
            props?.data?.contentfulEvents?.speakerDetails
              ?.speakerAgreementAsSessionSpeaker
              ?.speakerAgreementAsSessionSpeaker
          }
        </p>
        {/* <ul>
          <li>
            Publication of your abstract and name on the{" "}
            {props?.data?.contentfulEvents?.title} website
          </li>
          <li>One complimentary conference pass</li>
          <li>
            Inclusion in {props?.data?.contentfulEvents?.title} promotion
            efforts{" "}
          </li>
          <li>
            Automatic registration to the event (no extra forms to complete)
          </li>
          <li>Exposure to hundreds of Apache Kafka community members</li>
        </ul> */}
        <h3>Speaker Agreement</h3>
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props?.data?.contentfulEvents?.speakerDetails
              ?.speakerAgreementDetails?.speakerAgreementDetails
          }
        </p>
      </div>

      <div className="spk_ag_body_gray">
        <div className="spk_ag_fr_body">
          <div className="sponsors_fr_title">Speaker Agreement</div>
          <div className="sponsors_fr_main">
            <MarketoForm
              formId={props?.data?.contentfulEvents?.formId?.speakerAgreement}
              formType="speaker-agreement"
            />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
export default SpeakerAgreement
